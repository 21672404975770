// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // urlApi: 'http://localhost:1337/api',
  // url: 'http://localhost:4200',
  // urlUpload: 'http://localhost:1337',
  // urlAdm: '/dashboard',
  // tokenKey: 'id_token'

  urlApi: 'https://annuaire.cpc-occitanie.org/api',
  url: 'https://annuaire.cpc-occitanie.fr',
  urlUpload: 'https://annuaire.cpc-occitanie.org',
  urlAdm: '/dashboard',
  tokenKey: 'id_token'

  // urlApi: 'https://cpcapi.mannarbonne.fr/api',
  // url: 'https://cpc.mannarbonne.fr',
  // urlUpload: 'https://cpcapi.mannarbonne.fr',
  // urlAdm: '/dashboard',
  // tokenKey: 'id_token'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
