import { ModalModule } from './../../_modal/modal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SafeHtmlPipe} from '../../services/sanitize';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [SafeHtmlPipe],
  imports: [
    CommonModule,
    PdfViewerModule,
    ModalModule
  ],
  exports: [
    SafeHtmlPipe,
    PdfViewerModule,
    ModalModule
  ],
})
export class SharedModule { }
