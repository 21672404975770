import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { SharedModule } from './services/shared/shared.module';
import { LoadingInterceptor } from './loading/loading.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';


class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    // do something with the exception
    console.error(error.stack);
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    // AuthService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initAppService,
    //   deps: [AuthService],
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {provide: ErrorHandler, useClass: MyErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function initAppService(appService: AuthService) {
//   return () => appService.init();
// }
