import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';

import { ModalService } from './modal.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'jw-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    @Input() titre = 'Titre';
    @Input() action = '';
    @Input() size1 = 'w-11/12 md:max-w-md';
    @Input() size2 = ''
    @Output() sok: EventEmitter<boolean> = new EventEmitter();

    constructor(private modalService: ModalService, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        if(isPlatformBrowser(this.platformId)) {
          // move element to bottom of page (just before </body>) so it can be displayed above everything else
          document.body.appendChild(this.element);

          // close modal on background click
          this.element.addEventListener('click', el => {
              if (el.target.className === 'jw-modal-background') {
                  this.close();
              }
          });
        }

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
      if(isPlatformBrowser(this.platformId)) {
        this.element.style.display = 'block';
        document.body.classList.add('jw-modal-open');
      }
    }

    // close modal
    close(): void {
        //console.log('ferme');
      if(isPlatformBrowser(this.platformId)) {
        this.element.style.display = 'none';
        document.body.classList.remove('jw-modal-open');
      }
    }

    sendOK() {
        this.sok.emit(true);
    }
}
