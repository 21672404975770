import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  message$: BehaviorSubject<Message> = new BehaviorSubject(null);
  afficheAlert = false;

  constructor() {
    this.message$.next({
      mode: 'success',
      titre:'Successfully saved!',
      texte: 'Anyone with a link can now view this file.'
    });
  }

  setMessage(msg: Message) {
    this.message$.next(msg);
    this.afficheAlert = true;
    setTimeout(()=>this.afficheAlert=false, 3000);
  }

  open() {
    this.afficheAlert = true;
  }
  close() {
    this.afficheAlert = false;
  }
}
