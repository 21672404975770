import { ViewportScroller } from '@angular/common';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Message, Modal } from './models/models';
import { AlertService } from './services/alert.service';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  message: Message;
  modalData: Modal;

  constructor(
    private alert: AlertService,
    private modal: ModalService,
    private router: Router,
    private viewportScroller: ViewportScroller
    //private loader: Loader
  ) {
    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      setTimeout(() => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }, 190);
    });
  }

  ngOnInit(): void {
    this.alert.message$.subscribe(msg => this.message = msg);
    this.modal.message$.subscribe(mod => this.modalData = mod);
  }
  get afficheAlert() {
    return this.alert.afficheAlert;
  }

  get afficheModal() {
    return this.modal.afficheModal;
  }

  set openClose(inter: boolean) {
    this.alert.afficheAlert = inter;
  }

  set openCloseModal(inter: boolean) {
    this.modal.afficheModal = inter;
  }

  set returnedValue(val: number) {
    this.modal.setValue(val);
    this.modal.afficheModal = false;
  }
}
