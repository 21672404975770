

<div [ngClass]="'jw-modal fixed inset-0 z-50 bg-gray-900 bg-opacity-75 flex items-center justify-center p-3'">
  <div [ngClass]="'text-gray-900 bg-white '+size1+' mx-auto rounded shadow-lg z-50 overflow-y-auto overflow-x-clip'">
    <div [ngClass]="'z-50 relative mx-auto my-0 max-w-full '+size2+' bg-white text-gray-700 rounded shadow-lg border flex flex-col overflow-hidden'">
      <div class="grid grid-cols-3 gap-4 px-3 py-1 text-xl border-b bg-blue-500 text-white w-full align-middle">
          <div class="col-span-2">{{titre}}</div>
          <div class="  w-full" dir="rtl">
              <svg (click)="close()" class="svg-icon cursor-pointer" viewBox="0 0 20 20">
                  <path fill="none" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
              </svg>
          </div>
      </div>
      <div class="p-4 flex-grow">
            <ng-content></ng-content>
      </div>
      <div class="px-6 py-3 border-t" *ngIf="action">
          <div class="flex justify-end">
              <button (click)="close()" type="button" class="bg-gray-700 text-gray-100 rounded px-4 py-2 mr-1">Annuler</button>
              <button (click)="sendOK()" type="button" class="bg-blue-600 text-gray-200 rounded px-4 py-2">{{action}}</button>
          </div>
      </div>
    </div>

  </div>

</div>


<!--<div class="jw-modal fixed inset-0 z-50 bg-gray-900 bg-opacity-75 flex items-center justify-center p-3">
  <div class="text-gray-900 bg-white w-full h-full  mx-auto rounded shadow-lg z-50 overflow-y-auto">
    <div class="z-50 relative mx-auto my-0 max-w-full h-full bg-white text-gray-700 rounded shadow-lg border flex flex-col overflow-hidden">
    -->
